var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"cms-pages-admin",attrs:{"title":_vm.$t('CMS Pages')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Page Name')}},[_c('b-form-select',{attrs:{"options":_vm.cmsPages},on:{"change":function($event){return _vm.pageChanged($event)}},model:{value:(_vm.dataInfo.id),callback:function ($$v) {_vm.$set(_vm.dataInfo, "id", $$v)},expression:"dataInfo.id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Status')}},[_c('b-form-select',{attrs:{"options":[
            {
              text: 'Active',
              value: '1',
            },
            {
              text: 'Inactive',
              value: '0',
            } ]},model:{value:(_vm.dataInfo.status),callback:function ($$v) {_vm.$set(_vm.dataInfo, "status", $$v)},expression:"dataInfo.status"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Content')}},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.dataInfo.content),callback:function ($$v) {_vm.$set(_vm.dataInfo, "content", $$v)},expression:"dataInfo.content"}})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveCMS}},[_vm._v(" "+_vm._s(_vm.$t('Save CMS Page'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }