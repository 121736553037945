<template>
  <b-card
    class="cms-pages-admin"
    :title="$t('CMS Pages')"
  >
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group :label="$t('Page Name')">
          <b-form-select
            v-model="dataInfo.id"
            :options="cmsPages"
            @change="pageChanged($event)"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group :label="$t('Status')">
          <b-form-select
            v-model="dataInfo.status"
            :options="[
              {
                text: 'Active',
                value: '1',
              },
              {
                text: 'Inactive',
                value: '0',
              },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group :label="$t('Content')">
          <ckeditor
            v-model="dataInfo.content"
            :editor="editor"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="saveCMS"
        >
          {{ $t('Save CMS Page') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormSelect, BButton } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useAdminUi } from './useAdmin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      editor: ClassicEditor,
      cmsPageData: [],
      cmsPages: [],
      dataInfo: {
        page_name: '',
        status: '',
        id: '',
        content: '',
      },
    }
  },
  mounted() {
    this.getCMSPages()
  },
  methods: {
    pageChanged($event) {
      // eslint-disable-next-line eqeqeq
      const pageInfo = this.cmsPageData.find(x => x.id == $event)
      if (!pageInfo) {
        return
      }
      const { getCMSPagesByText } = useAdminUi()

      showLoader()
      getCMSPagesByText(`/${pageInfo.page_name}`)
        .then(({ data }) => {
          if (data.statusCode === '200' && data.responseData) {
            // eslint-disable-next-line prefer-destructuring
            this.dataInfo = data.responseData[0]
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveCMS() {
      const { updateCMSPage } = useAdminUi()
      const formData = new FormData()
      formData.append('_token', '0P38vKQka0Ma6TM8I7R3T9Efga4ta3nEkrrrVzni')
      formData.append('id', this.dataInfo.id)
      formData.append('page_name', this.dataInfo.page_name)
      formData.append('content', this.dataInfo.content)
      formData.append('status', this.dataInfo.status)
      formData.append('_method', 'PATCH')

      showLoader()
      updateCMSPage({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            this.pageChanged()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCMSPages() {
      const { getCMSPages } = useAdminUi()

      showLoader()
      getCMSPages()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cmsPageData = data.responseData
            if (this.cmsPageData && this.cmsPageData[0] && !this.dataInfo.id) {
              // eslint-disable-next-line prefer-destructuring
              this.dataInfo = window.SuperApp.actions.cloneDeep(this.cmsPageData[0])
            }
            this.cmsPages = window.SuperApp.actions.cloneDeep(
              this.cmsPageData.map(x => ({
                text: window.SuperApp.actions.replaceUnderscore(x.page_name.toUpperCase()),
                value: x.id,
              })),
            )
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.cms-pages-admin .ck.ck-editor__top.ck-reset_all {
  display: block !important;
}
</style>
